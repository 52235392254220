import React from 'react'
import './scss/index.scss'
import translate from '../i18n'
import {
  FaThumbsUp,
  FaInfoCircle,
  FaExclamationTriangle,
  FaClock,
  FaExternalLinkAlt
} from 'react-icons/fa'
import AffiliateLinkButton from '../AffiliateLinkButton'

function LenderHeader({ lender, currentLanguage }) {
  return (
    <div className="lender_header">
      <div className="section__header dark title-container">
        <h1 className="main-title">{lender.frontmatter.title}</h1>
        <p className="tagline">{lender.frontmatter.tagline}</p>
      </div>
      <div className="flex-split-responsive pt20">
        <div className="bullet-points">
          <h2>
            <FaExclamationTriangle size={24} /> {lender.frontmatter.title} krav
            och villkor
          </h2>
          <ul className="list">
            {lender.frontmatter.requirements_bullet_points &&
              lender.frontmatter.requirements_bullet_points.map((item, i) => (
                <li key={i}>
                  <span>{item.bullet_point}</span>
                </li>
              ))}
          </ul>
          <div>
            <AffiliateLinkButton
              url={lender.frontmatter.affiliate_link}
              text={translate('button_go_to_lender', currentLanguage)}
            />
          </div>
        </div>
        <img
          src={lender.frontmatter.featured_image}
          alt={lender.frontmatter.title}
          className="bg"
        />
      </div>
      {lender.frontmatter.is_high_interest_loan && (
        <>
          <hr />
          <div className="high_interest_warning">
            <img src="/images/warning.png" alt="varning, högkostnadskredit" />
            <p>
              Det här är en högkostnadskredit Om du inte kan betala tillbaka
              hela skulden riskerar du en betalningsanmärkning. För stöd, vänd
              dig till budget- och skuldrådgivningen i din kommun.
              Kontaktuppgifter finns på{' '}
              <a href="https://www.hallakonsument.se" target="_blank">
                hallakonsument.se{' '}
                <FaExternalLinkAlt style={{ marginBottom: 4 }} size="12" />
              </a>
            </p>
          </div>
        </>
      )}
      <hr />
      <div className="flex-split-responsive">
        <div className="bullet-points">
          <h2>
            <FaThumbsUp size="24" /> Bra att veta
          </h2>
          <ul className="list">
            {lender.frontmatter.bullet_points &&
              lender.frontmatter.bullet_points.map((item, i) => (
                <li key={i}>
                  <span>{item.bullet_point}</span>
                </li>
              ))}
          </ul>
        </div>
        <div className="bullet-points">
          <h2>
            <FaInfoCircle size={24} /> Kontaktuppgifter
          </h2>
          <ul className="list">
            {lender.frontmatter.address_details &&
              lender.frontmatter.address_details.map((item, i) => (
                <li key={i}>
                  <span>{item.line}</span>
                </li>
              ))}
          </ul>
          <h2>
            <FaClock size={24} /> Öppettider
          </h2>
          <ul className="list">
            {lender.frontmatter.opening_hours &&
              lender.frontmatter.opening_hours.map((item, i) => (
                <li key={i}>
                  <span>{item.line}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LenderHeader
