import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import ReviewSchema from '../components/ReviewSchema'
import LenderHeader from '../components/LenderHeader'
// import StickyMobileMenu from '../components/StickyMobileMenu/lender'
import AdSection from '../components/AdSection'
import ContentSection from '../components/ContentSection'
import site_config from '../config/site'

function Lender(props) {
  const lender = props.data.markdownRemark
  const currentLanguage = 'sv'

  return (
    <>
      <Helmet>
        <ReviewSchema
          schema={{
            name: lender.frontmatter.title,
            ratingValue: lender.frontmatter.rating,
            authorName: 'Bluemonday.se',
            logo: lender.frontmatter.logo
          }}
        />
      </Helmet>
      <Layout location={props.location} title={lender.frontmatter.title}>
        <article className="lender-review">
          <ProgressiveBgImage
            hqImage="/images/office-desk.jpg"
            lqImage="/images/office-desk-lq.jpg"
          >
            <HeaderMeta
              title={lender.frontmatter.title}
              description={lender.frontmatter.description}
              canonical={`${site_config.url}/lender${lender.fields.slug}`}
            />
            <section className="section hero-box">
              <LenderHeader lender={lender} currentLanguage={currentLanguage} />
            </section>

            <AdSection
              affiliate_link={lender.frontmatter.affiliate_link}
              tagline={`Besök  ${lender.frontmatter.title} för aktuella räntor`}
              image={lender.frontmatter.featured_image}
              image_alt={lender.frontmatter.title}
            />

            <ContentSection className={'dark'} markdown_content={lender.html} />
          </ProgressiveBgImage>
        </article>
      </Layout>
    </>
  )
}

export default Lender

export const pageQuery = graphql`
  query LenderBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        description
        title
        tagline
        rating
        bullet_points {
          bullet_point
        }
        ribbon
        interest_rate
        effective_interest_rate
        min_loan_amount
        max_loan_amount
        min_loan_period
        max_loan_period
        is_high_interest_loan
        lender_type
        accepts_non_payment_record
        requirements_bullet_points {
          bullet_point
        }
        age_limit
        description
        affiliate_link
        featured_image
        content_type
        address_details {
          line
        }
        opening_hours {
          line
        }
      }
    }
  }
`
