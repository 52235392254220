import React from 'react'
import AffiliateLinkButton from '../AffiliateLinkButton'
import './scss/index.scss'
import translate from '../i18n'

const AdSection = ({  image, image_alt, className, affiliate_link, tagline }) => {
  return (
    <section className={`section content-box ${className}`}>
      <div className="ad_section">
        <img
          className="ad_image"
          src={image}
          alt={image_alt}
        />
        <span>{tagline}</span>
        <AffiliateLinkButton
          text={translate('button_go_to_lender', 'sv')}
          url={affiliate_link}
        />
      </div>
    </section>
  )
}

export default AdSection