import React from 'react'

export default function ReviewSchema({
  name = 'N/A',
  ratingValue = 4,
  authorName = 'Bluemonday.se',
  logo = ''
}) {
  return (
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "Review",
          "itemReviewed": {
              "@type": "Thing",
              "name": "${name}"
              "image": "${logo}"
          },
          "reviewRating": {
              "@type": "Rating",
              "ratingValue": "${ratingValue}",
              "bestRating": 5
          },
          "author": {
              "@type": "Organization",
              "name": "${authorName}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "${authorName}"
          },
          "reviewBody": ""
        }
      `}
    </script>
  )
}
